<template>
  <div>
    <b-modal id="detailEvent" ref="detailEvent" :title="title">
      <b-alert variant="warning" :show="generalMsgExists">{{ generalMsg }}</b-alert>
      <b-card>
        <b-card-text>
          <b-form @submit="onSubmit" @submit.stop.prevent novalidate>
            <b-form-group  class="mt-4">
              <label  for="feedback-firstName">{{$t('myCollection.myEvents.eventName')}}</label>
              <b-input class="mb-2 mr-sm-2 mb-sm-0" v-model="eventName" :state="eventNameValidation" id="feedback-eventName"></b-input>
              <b-form-invalid-feedback v-if="showMsg" :state="eventNameValidation">
                {{$t('errors.eventNameValidation')}}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group  class="mt-4">
              <label  for="feedback-eventDate">{{$t('myCollection.myEvents.date')}} </label>
              <b-input type="date" class="mb-2 mr-sm-2 mb-sm-0" v-model="eventDate" id="feedback-eventDate"></b-input>
            </b-form-group>
            <b-form-group  class="mt-4">
              <label for="feedback-maxPrice">{{$t('myCollection.myEvents.maxPrice')}}</label>
              <b-input-group prepend="€">
                <b-input v-model="eventMaxPrice" :state="maxPriceValidation" id="feedback-maxPrice"></b-input>
              </b-input-group>
              <b-form-invalid-feedback v-if="showMsg" :state="maxPriceValidation">
                {{$t('errors.maxPriceValidation')}}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group  class="mt-4">
              <label for="feedback-santa">{{$t('myCollection.myEvents.drawSanta')}}</label>
              <b-form-select v-model="eventSanta" class="mb-3" >
                <b-form-select-option :value=true>{{$t('Yes')}}</b-form-select-option>
                <b-form-select-option :value=false>{{$t('No')}}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-form>
        </b-card-text>
      </b-card>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          {{ $t('myCollection.cancelButton')}}
        </b-button>
        <b-button size="sm" variant="success" @click="onSubmit">
          {{ valideButton }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ENV from "../../env.config";

/**
 * Component used to display a modal containing all informations for a specific event. It used to create or update a event.
 * @displayName detailEvent
 */
export default {
  name: 'detailEvent',
  data() {
    return {
      /**
       * variable used to store the event input
       */
      eventName:"",
      /**
       * variable used to store the date input
       */
      eventDate:"",
      /**
       * variable used to store the maxPrice input
       */
      eventMaxPrice:"",
      /**
       * variable used to store in local the field attendingParticipants
       */
      eventParticipants:"",
      /**
       * variable used to store drawSanta input
       */
      eventSanta:false,
      /**
       * boolean used to show error message when validate
       */
      showMsg:false,
      /**
       * String used to show general message when validate
       */
      generalMsg:"",
    }
  },
  props:{
    /**
     * props containing the data for a specific participant
     */
    data:Object,
    /**
     * props containing the title to display in the card
     */
    title: String,
    /**
     * props containing the name of the button to display
     */
    valideButton: String,
  },
  computed:{
    /**
     * @ignore
     */
    generalMsgExists(){
      if (this.generalMsg.length == 0){
        return false
      }
      return true
    },
    eventNameValidation(){
      if (this.showMsg){
        return this.eventName.length >= 2 && this.eventName.length <=100
      }
      return null
    },
    maxPriceValidation(){
      if (this.showMsg){
        return !isNaN(this.eventMaxPrice)
      }
      return null
    },
  },
  watch: {
    eventName: function(value){
      this.eventName = value
    },
    eventDate: function(value){
      this.eventDate = value
    },
    eventMaxPrice: function(value){
      this.eventMaxPrice = value
    },
    eventSanta: function(value){
      this.eventSanta = value
    },
    data: function(value){
      this.eventName = value.name
      if (value.date !== undefined){
        this.eventDate = value.date.substr(0,10)
      } else {
        this.eventDate= ""
      }
      this.eventMaxPrice = value.maxGiftPrice
      this.eventSanta = value.drawSanta
      this.eventParticipants = value.attendingParticipants
    }
  },
  methods: {
    /**
     * Method used when a user press to validate the form
     * @params {events} evt
     */
    onSubmit(evt) {
      evt.preventDefault()
      this.showMsg = true
      this.$store.commit('getMySecretSantaToken')
      if (this.maxPriceValidation && this.eventNameValidation && this.eventDate && this.santaValidation && this.$store.state.isConnected){
        this.showMsg = false
        const body = {
          name: this.eventName,
          date: this.eventDate,
          maxGiftPrice: this.eventMaxPrice,
          attendingParticipants: this.eventParticipants,
          drawSanta: this.eventSanta
        }
        if (this.valideButton == "Create"){
          fetch(`${ENV.API_PREFIX}/api/events`, {
            method: "POST",
            headers: {
              "Authorization": "Bearer "+this.$store.state.accessToken,
              "content-type": "application/json"
            },
            body: JSON.stringify(body)
          })
            .then(res => {
              if (res.status === 400){
                this.generalMsg = "Name already exists"
                this.showMsg = true;
              } else if (res.status === 404){
                this.generalMsg = "Oupss"
                this.showMsg = true;
              } else if (res.status === 201) {
                this.$root.$emit('bv::toggle::modal', 'detailEvent', '#manage-event')
                this.$store.dispatch('getMyEvents')
                this.$parent.fetchData()
              }
            })
            .catch(()=>{
              this.generalMsg="Connexion issue"
            });
        } else if (this.valideButton == "Update"){

          fetch(`${ENV.API_PREFIX}/api/events/${this.data.id}`, {
            method: "PUT",
            headers: {
              "Authorization": "Bearer "+this.$store.state.accessToken,
              "content-type": "application/json"
            },
            body: JSON.stringify(body)
          })
            .then(res => {
              if (res.status === 400){
                this.generalMsg = res.statusText
                this.showMsg = true;
              } else if (res.status === 404){
                this.generalMsg = res.statusText
                this.showMsg = true;
              } else if (res.status === 200) {
                this.$store.dispatch('getMyEvents')
                this.$parent.fetchData()
                this.$root.$emit('bv::toggle::modal', 'detailEvent', '#manage-event')
              }
            })
            .catch(()=>{
            this.$store.commit("setGeneralMsg", {
              type: "error",
              msg: this.$t('errors.connexionIssue')
            });
            })
        }
      }
    }
  }
}
</script>
