<template>
  <div id="profile">
    <Navbar />
    <b-alert variant="warning" :show="generalMsgExists">{{ generalMsg }}</b-alert>
    <b-card-group deck class="m-3">
      <DetailUser title="Update profile" nameButton="Update"/>
    </b-card-group>
  </div>
</template>

<script>
import Navbar from "@/components/navBar.vue"
import DetailUser from "@/components/detailUser.vue"
export default {
  name: 'Profile',
  components: {
    Navbar,
    DetailUser,
  },
  data() {
    return {
      showMsg:false,
      generalMsg:"",
    }
  },
  computed:{
    generalMsgExists(){
      if (this.generalMsg.length == 0){
        return false
      }
      return true
    },
  },
  mounted() {



  },
  methods: {
  }
}
</script>
