<template>
  <div id="resume-event">
    <b-alert class="text-center" variant="danger" :show="generalMsgExists" v-if="$store.state.generalMsg.error !== ''">
  {{ $store.state.generalMsg.error }}
    </b-alert>
    <b-alert class="text-center" variant="warning" :show="generalMsgExists" v-else-if="$store.state.generalMsg.warning !== ''">
    {{ $store.state.generalMsg.warning }}
    </b-alert>
    </div>
</template>
<script>

/**
 * Component used to manage the display the banner with error or warning stored in the $store
 * @displayName bannerMsg
 */
export default {
  name: 'bannerMsg',
  computed:{
    generalMsgExists(){
      if (this.$store.state.generalMsg.error === "" && this.$store.state.generalMsg.warning === ""){
        return false
      }
      return true
    },
  }
}
</script>
