import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./locale";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Dayjs from "vue-dayjs";

Vue.use(Dayjs);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    this.$store.commit("resetUserData");
  },
  created: function() {
    document.title = "My Secret Santa";
  },
  render: h => h(App)
}).$mount("#app");
