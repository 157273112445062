import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem("MySecretSantaLocale"),
  messages: {
    fr: {
      MenuMyEvents: "Mes évenements",
      MenuMyParticipants: "Mes participants",
      MenuUser: "Utilisateur",
      MenuProfile: "Mon profil",
      MenuLogin: "S'identifier",
      MenuSignout: "Déconnexion",
      MenuRegister: "S'enregistrer",
      MenuSearchEvents: "Rechercher un évenement",
      MenuSearchParticipants: "Rechercher un participant",
      ButtonBack: "Retour",
      Yes: "Oui",
      No: "Non",
      deleteButton: "Supprimer",
      launch: "Lancer",
      none: "Aucun",

      sign: {
        firstName: "Prénom",
        lastName: "Nom",
        login: "Surnom",
        email: "Email",
        password: "Mot de passe",
        c_password: "Confirmation du mot de passe",
        titlelogin: "Connexion",
        loginButton: "S'authentifier",
        signupButton: "S'enregistrer",
        inputLogin: "Entrer votre surnom",
        inputPassword: "Entrer votre mot de passe",
        rememberMe: "Se souvenir de moi"
      },
      myCollection: {
        myEvents: {
          search: "Recherche un évenement",
          title: "Mes évenements",
          createButtonTitle: "Créer un nouvel évenement",
          createPopupTitle: "Créer un évenement",
          updatePopupTitle: "Mettre à jour un évenement",
          maxGiftPrice: " Prix des cadeaux maximum",
          nbParticipants: "Nb de participants",
          creator: "Créateur",
          launchDraw: "Lancer le tirage au sort",
          launchSantaDraw: "Lancer le tirage au sort du santa",
          resetDraw: "Réinitialiser le tirage au sort",
          resetSantaDraw: "Réinitialiser le tirage au sort du santa",
          checkEvent: "Vérifier l'évenement",
          remainingTime: "Temps restant",
          days: "jours",
          tooLate: "Trop tard",
          today: "Aujourd'hui. Soit prêt",
          maxPrice: "Montant maximum",
          eventName: "Libellé",
          date: "Date",
          drawSanta: "Tirage au sort du santa"
        },
        myParticipants: {
          search: "Rechercher un participant",
          title: "Mes participants",
          createButtonTitle: "Créer un nouveau participant",
          createPopupTitle: "Créer un participant",
          updatePopupTitle: "Mettre à jour un participant",
          dontPairWith: "Ne pas associer avec",
          isSantaAt: "A été Santa lors",
          addConstraints: "Ajout de contraintes"
        },
        manageParticipants: {
          title: "Gestion des participants",
          help:
            "Utiliser Ctrl et/ou shift pour sélectionner plusieurs participants",
          nbSelected: "Nb de participants sélectionnés"
        },
        noResult: "Aucun résultat",
        createButton: "Créer",
        updateButton: "Mettre à jour",
        cancelButton: "Annuler",
        resetButton: "Réinitialiser",
        validateButton: "Valider l'évenement"
      },
      draw: {
        giver: "Donneur",
        receiver: "Receveur",
        drawSummary: "Tirage au sort",
        noSanta: "Pas de santa",
        theSantaIs: "Le santa est",
        revelation: "Révélation du tirage au sort",
        listParticipants: "Liste des participants",
        revealButton: "Révéler le tirage au sort",
        noRevealButton: "Cacher",
        title: "Paramètre du tirage au sort",
        maxTry: "Nombre de tentative pour réussir le tirage au sort",
        nbEventToCheck: "Nombre d'évenement en arrière à prendre en compte",
        sendEmails: "Envoie automatique des mails après tirage au sort",
        errorMaxTry: "Ce champ doit être un nombre supérieure à 0"
      },
      errors: {
        nameValidationError: "Votre champ doit contenir entre 3-16 caractères.",
        emailValidationError: "Votre email est invalide",
        maxPriceValidation: "Le prix maximum doit être un number supérieur à 0",
        eventNameValidation:
          "Le nom doit avoir une longueur comprise entre 2 et 100 caractères",
        connexionIssue: "Erreur de connexion",
        jwtExpired: "JWT expiré. Veillez vous reconnecter",
        accessDenied: "Accés refusé",
        emailAndPasswordMistake: "Email et mot de passe de correspondent pas"
      },
      confirmation: {
        question: "Etes vous sure ?"
      }
    },
    en: {
      MenuMyEvents: "My events",
      MenuMyParticipants: "My participants",
      MenuUser: "User",
      MenuProfile: "My profil",
      MenuLogin: "Login",
      MenuSignout: "Logout",
      MenuRegister: "Register",
      ButtonBack: "Back",
      myProfileTitle: "My profile",
      Yes: "Yes",
      No: "No",
      launch: "Launch",
      none: "None",
      deleteButton: "Delete",
      sign: {
        firstName: "First name",
        lastName: "Last name",
        login: "Login",
        titlelogin: "Login",
        loginButton: "Sign in",
        signupButton: "Sign up",
        email: "Email",
        password: "Password",
        signuptitle: "Sign-up",
        c_password: "Password confirmation",
        inputLogin: "Enter your login here",
        inputPassword: "Enter your password",
        rememberMe: "Remember me"
      },
      myCollection: {
        myEvents: {
          search: "Search an event",
          title: "My events",
          createButtonTitle: "Create a new event",
          createPopupTitle: "Create an event",
          updatePopupTitle: "Update an event",
          maxGiftPrice: " Max gift price",
          nbParticipants: "Nb of participants",
          creator: "Creator",
          launchDraw: "Launch the draw",
          launchSantaDraw: "Launch the draw santa",
          resetDraw: "Reset the draw",
          resetSantaDraw: "Reset the draw santa",
          checkEvent: "Check the event",
          remainingTime: "Remaining time",
          days: "days",
          tooLate: "Too late",
          today: "Today. Be ready",
          maxPrice: "Price max",
          eventName: "Event name",
          date: "Date",
          drawSanta: "Draw santa"
        },
        myParticipants: {
          search: "Search a participant",
          title: "My participants",
          createButtonTitle: "Create a new participant",
          createPopupTitle: "Create a participant",
          updatePopupTitle: "Update a participant",
          dontPairWith: "Don't pair with",
          isSantaAt: "Is santa at",
          addConstraints: "Add constraints"
        },
        manageParticipants: {
          title: "Manage Participants",
          help: "Use Ctrl or/and shift to select many participants",
          nbSelected: "Nb of participants selected"
        },
        noResult: "No result",
        createButton: "Create",
        updateButton: "Update",
        cancelButton: "Cancel",
        resetButton: "Reset",
        validateButton: "Validate the event"
      },
      draw: {
        giver: "giver",
        receiver: "receiver",
        drawSummary: "Draw",
        noSanta: "No santa",
        theSantaIs: "The santa is",
        revelation: "Draws revelation",
        listParticipants: "List of participants",
        revealButton: "Reveal the draw",
        noRevealButton: "Unreveal",

        title: "Paramètre du tirage au sort",
        maxTry: "Nombre de tentative pour réussir le tirage au sort",
        nbEventToCheck: "Nombre d'évenement en arrière à prendre en compte",
        sendEmails: "Envoie automatique des mails après tirage au sort",
        errorMaxTry: "Ce champ doit être un nombre supérieure à 0"
      },

      errors: {
        nameValidationError: "Your name must be 3-16 characters long.",
        emailValidationError: "Your email is invalid",
        maxPriceValidationError: "The price must be a number greater than 0",
        eventNameValidationError:
          "The event name must be 2-100 characters long",
        connexionIssue: "Connection issue",
        jwtExpired: "JWT expired. You have to login again",
        accessDenied: "Acces forbidden",
        emailAndPasswordMistake: "Email and password don't match"
      },
      confirmation: {
        question: "Are you sure ?"
      }
    }
  }
});

export default i18n;
