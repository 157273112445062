<template>
  <div id="register">
    <Navbar />
    <BannerMsg />
    <b-card-group deck class="m-3">
      <DetailUser :title="$t('MenuRegister')" :nameButton="$t('sign.signupButton')"/>
    </b-card-group>
    <FooterMain/>
  </div>
</template>

<script>
import Navbar from "@/components/navBar.vue"
import BannerMsg from "@/components/bannerMsg.vue"
import DetailUser from "@/components/detailUser.vue"
import FooterMain from "@/components/footerMain.vue"

export default {
  name: 'Register',
  components: {
    Navbar,
    BannerMsg,
    FooterMain,
    DetailUser
  },
  mounted() {
    if (this.$store.state.isConnected){
      this.$router.push({name:"Events"})
    }
  },
}
</script>
