<template>
  <div id="manage-events">
    <Navbar />
    <BannerMsg />
    <b-form-input class=" my-2 w-50 mx-auto" v-model="searchEvent" :placeholder="$t('myCollection.myEvents.search')"></b-form-input>

    <b-card-group deck class="m-3 mx-auto" style="max-width:50rem; min-width:20rem;">
      <b-card header-tag="header"
              header-bg-variant="light"
              footer-tag="footer"
              footer-bg-variant="light"
              align="center"
              >
              <template #header>
                <b-card-text class="d-flex" >
                  <h5 class="ml-2 mr-auto">{{ $t('myCollection.myEvents.title')}} </h5>
                  <b-button class="mr-2 ml-auto" @click="createEvent" ref="createEvent" variant="warning">{{ $t('myCollection.myEvents.createButtonTitle')}}</b-button>
                </b-card-text>
              </template>
              <b-card-text v-if="dataPaginate.length === 0">
                {{ $t('myCollection.noResult')}}
              </b-card-text>
              <b-card-text v-else>
                <b-card-text v-for="event in dataPaginate" :key="event.id">
                  <IndexEvents :data="event" v-on:delete-event="showModalConfirmation" v-on:update-event="updateEvent" v-on:manage-participants="manageParticipants" v-on:draw-santa="manageSanta" v-on:reset-santa="manageSanta"  v-on:draw-event="showModalLaunchDraw" v-on:reset-draw-event="manageDrawEvent" />
                </b-card-text>
              </b-card-text>
              <template #footer class="flex text-center">
                <b-pagination  v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center">
                </b-pagination>
              </template>
      </b-card>
    </b-card-group>
    <DetailEvent v-if="selectedEvent" :title="$t('myCollection.'+title)" :valideButton="$t('myCollection.'+nameButton)" :data="selectedEvent" />
    <ParticipantsSelection v-if="selectedEvent" :id="selectedEvent.id" :data="selectedEvent.attendingParticipants" collection="events" />
    <ModalConfirmation v-on:delete="deleteEvent"  />
    <ModalLaunchDraw :eventId="selectedEvent.id" v-on:launch="launchDraw"  />
    <FooterMain/>
  </div>
</template>

<script>
import Navbar from "@/components/navBar.vue"
import BannerMsg from "@/components/bannerMsg.vue"
import DetailEvent from "@/components/detailEvent.vue"
import ModalConfirmation from "@/components/modalConfirmation.vue"
import ModalLaunchDraw from "@/components/modalLaunchDraw.vue"
import IndexEvents from "@/components/indexEvents.vue"
import ParticipantsSelection from "@/components/participantsSelection.vue"
import FooterMain from "@/components/footerMain.vue"
import ENV from "@/../env.config";

/**
 * View used to manage all events of the current user
 * @displayName Manage-Events
 */
export default {
  name: 'Manage-Events',
  components: {
    IndexEvents,
    ModalLaunchDraw,
    FooterMain,
    DetailEvent,
    ModalConfirmation,
    Navbar,
    BannerMsg,
    ParticipantsSelection
  },
  data() {
    return {
      dataPaginate:[],
      dataSearch:[],
      currentPage: 1,
      perPage:10,
      title:"myEvents.createPopupTitle",
      visibleModal:false,
      nameButton:"createButton",
      searchEvent:"",
      selectedEvent: {},
      selectedIdEvent:"",
      selectedParticipants:[],
      generalMsg:"",
      showMsg:false,
    }
  },
  mounted(){
    if (this.$store.state.myParticipants.length === 0){
      this.$store.dispatch('getMyParticipants')
    }
    if (!this.$store.state.isConnected){
      this.$router.push({name:"Login"})
    }
    this.fetchData()
  },
  watch:{
    searchEvent(){
      if (this.searchEvent === ""){
        this.dataSearch = this.dataInit
      } else {
        this.dataSearch = this.dataInit.filter(event => event.name.toLowerCase().includes(this.searchEvent.toLowerCase()))
      }
      this.currentPage = 1
      this.extractPaginate()
    },
    currentPage(){
      this.extractPaginate()
    },
    dataInit(){
      this.dataSearch = this.dataInit
      this.extractPaginate()
    }
  },
  computed:{
    totalRows(){
      return this.dataSearch.length
    },
    dataInit(){
      return this.$store.state.myEvents
    }
  },
  methods: {
    extractPaginate(){
      this.dataPaginate = this.dataSearch.slice((this.currentPage -1)* this.perPage, this.currentPage * this.perPage)
    },
    manageParticipants(event){
      this.selectedEvent = event
      if (this.$store.state.myParticipants.length === 0){
        this.$store.dispatch('getMyParticipants')
      }
      this.$root.$emit('bv::toggle::modal', 'manageParticipants', 'createEvent')
    },
    showModalLaunchDraw(id){
      this.selectedEvent = {'id':id}
      this.$root.$emit('bv::toggle::modal', 'modalLaunchDraw', 'createEvent')
    },
    showModalConfirmation(id){
      this.selectedEvent = {'id':id}
      this.$root.$emit('bv::toggle::modal', 'modalConfirmation', 'createEvent')
    },
    deleteEvent(){
      this.$store.commit('getMySecretSantaToken')
      if (this.$store.state.isConnected){
      fetch(`${ENV.API_PREFIX}/api/events/${this.selectedEvent.id}`, {
        method: "DELETE",
        headers:{
          "Authorization": "Bearer "+this.$store.state.accessToken,
        }
      })
        .then((res) =>  {
          if(res.status === 204) {
            this.fetchData()
          } else {
            this.$store.commit("setGeneralMsg", {
              type: "error",
              msg: this.$t('errors.accesDenied')
            });
          }
        })
        .catch(()=>{
          this.$store.commit("setGeneralMsg", {
            type: "error",
              msg: this.$t('errors.connexionIssue')
          });
        });
      }
    },
    updateEvent(event){
      this.nameButton = "updateButton"
      this.title = "myEvents.updatePopupTitle"
      this.selectedEvent = event
      this.$root.$emit('bv::toggle::modal', 'detailEvent', 'createEvent')
    },
    createEvent(){
      this.nameButton = "createButton"
      this.title = "myEvents.createPopupTitle"
      this.selectedEvent = {}
      this.$root.$emit('bv::toggle::modal', 'detailEvent', 'createEvent')
    },
    launchDraw(params){
      this.manageDrawEvent(this.selectedEvent.id, 'POST',params)

    },
    manageDrawEvent(eventId, methodUsed, params=null){
      this.$store.commit('getMySecretSantaToken')
      if (this.$store.state.isConnected){
      fetch(`${ENV.API_PREFIX}/api/events/${eventId}/gift_draws`, {
        method: methodUsed,
        headers:{
          "Authorization": "Bearer "+this.$store.state.accessToken,
        },
        body:JSON.stringify(params)
      })
        .then((res) =>  {
          if(res.status === 200) {
            this.$store.dispatch('getMyEvents')
            this.fetchData()
          } else {
            this.$store.commit("setGeneralMsg", {
              type: "error",
              msg: this.$t('errors.accesDenied')
            });
          }
        })
        .catch(()=>{
          this.$store.commit("setGeneralMsg", {
            type: "error",
              msg: this.$t('errors.connexionIssue')
          });
        });
      }
    },

    manageSanta(eventId, methodUsed){
      this.$store.commit('getMySecretSantaToken')
      if (this.$store.state.isConnected){
      fetch(`${ENV.API_PREFIX}/api/events/${eventId}/santa`, {
        method: methodUsed,
        headers:{
          "Authorization": "Bearer "+this.$store.state.accessToken,
        }
      })
        .then((res) =>  {
          if(res.status === 200) {
            this.$store.dispatch('getMyEvents')
            this.fetchData()
          } else {
            this.$store.commit("setGeneralMsg", {
              type: "error",
              msg: this.$t('errors.accesDenied')
            });
          }
        })
        .catch(()=>{
          this.$store.commit("setGeneralMsg", {
            type: "error",
              msg: this.$t('errors.connexionIssue')
          });
        });
      }
    },
    fetchData(){
      this.$store.dispatch('getMyEvents')
      this.dataSearch = this.dataInit
      this.extractPaginate()
    },
  }
}
</script>
