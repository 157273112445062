<template>
  <div id="manage-participants">
    <Navbar />
    <BannerMsg />
    <b-form-input class=" my-2 w-50 mx-auto" v-model="searchParticipant" :placeholder="$t('myCollection.myParticipants.search')"></b-form-input>

    <b-card-group deck class="m-3 mx-auto" style="max-width:50%; min-width:20rem;">
      <b-card header-tag="header"
              header-bg-variant="light"
              footer-tag="footer"
              footer-bg-variant="light"
              align="center"
              >
              <template #header>
                <b-card-text class="d-flex" >
                  <h5 class="ml-2 mr-auto"> {{ $t('myCollection.myParticipants.title')}} </h5>
                  <b-button class="mr-2 ml-auto" @click="createParticipant" ref="createParticipant" variant="warning">{{ $t('myCollection.myParticipants.createButtonTitle')}}</b-button>
                </b-card-text >
              </template>
              <b-card-text v-if="dataPaginate.length == 0">
                {{ $t('myCollection.noResult')}}
              </b-card-text>
              <b-card-text v-else>
                <b-card-text v-for="participant in dataPaginate" :key="participant.id">
                  <IndexParticipants :data="participant" v-on:delete-participant="showModalConfirmation" v-on:update-participant="updateParticipant" v-on:manage-participants="manageParticipants"/>
                </b-card-text>
              </b-card-text>
              <template #footer  class="flex text-center">
                <b-pagination  v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center">
                </b-pagination>
              </template>
      </b-card>
    </b-card-group>
    <DetailParticipant :title="$t('myCollection.'+title)" :valideButton="$t('myCollection.'+nameButton)" :data="selectedParticipant" />
    <ModalConfirmation v-on:delete="deleteParticipant"  />
    <ParticipantsSelection :id="selectedParticipant.id" :data="selectedParticipant.dontPairWith" collection="participants"  />
    <FooterMain/>
  </div>
</template>

<script>
import Navbar from "@/components/navBar.vue"
import BannerMsg from "@/components/bannerMsg.vue"
import DetailParticipant from "@/components/detailParticipant.vue"
import ModalConfirmation from "@/components/modalConfirmation.vue"
import IndexParticipants from "@/components/indexParticipants.vue"
import ParticipantsSelection from "@/components/participantsSelection.vue"
import FooterMain from "@/components/footerMain.vue"
import ENV from "@/../env.config";

export default {
  name: 'Manage-Participants',
  components: {
    IndexParticipants,
    DetailParticipant,
    FooterMain,
    Navbar,
  ModalConfirmation,
    ParticipantsSelection,
    BannerMsg,
  },
  data() {
    return {
      dataSearch:[],
      dataPaginate:[],
      currentPage: 1,
      perPage:10,
      title:"myParticipants.createPopupTitle",
      visibleModal:false,
      nameButton:"createButton",
      searchParticipant:"",
      selectedParticipant: {
      },
      generalMsg:"",
      showMsg:false,
    }
  },
  mounted(){
    if (!this.$store.state.isConnected){
      this.$router.push({name:"Login"})
    }
    if (this.$store.state.myEvents.length === 0){
      this.$store.dispatch('getMyEvents')
    }
    this.fetchData()
  },
  watch:{
    searchParticipant(){
      if (this.searchParticipant === ""){
        this.dataSearch = this.dataInit
      } else {
        this.dataSearch = this.dataInit.filter(event => { return event.firstName.toLowerCase().includes(this.searchParticipant) ||event.lastName.toLowerCase().includes(this.searchParticipant.toLowerCase())})
      }
        this.currentPage = 1
        this.extractPaginate()
    },
    currentPage(){
      this.extractPaginate()
    },
    dataInit(){
      this.dataSearch = this.dataInit
      this.extractPaginate()
    }
  },
  computed:{
    totalRows(){
      return this.dataSearch.length
    },
    dataInit(){
      return this.$store.state.myParticipants
    }
  },
  methods: {
    extractPaginate(){
      this.dataPaginate = this.dataSearch.slice((this.currentPage -1)* this.perPage, this.currentPage * this.perPage)
    },
    showModalConfirmation(id){
      this.selectedParticipant = {'id':id}
      this.$root.$emit('bv::toggle::modal', 'modalConfirmation', 'createParticipant')
    },
    manageParticipants(participant){
      this.selectedParticipant = participant
      if (this.$store.state.myParticipants.length === 0){
        this.$store.dispatch('getMyParticipants')
      }
      this.$root.$emit('bv::toggle::modal', 'manageParticipants', 'createEvent')
    },
    deleteParticipant(){
      this.$store.commit('getMySecretSantaToken')
      if (this.$store.state.isConnected){
      fetch(`${ENV.API_PREFIX}/api/participants/${this.selectedParticipant.id}`, {
        method: "DELETE",
        headers:{
          "Authorization": "Bearer "+this.$store.state.accessToken,
        }
      })
        .then(res =>  {
          if(res.status === 204) {
            this.$store.dispatch('getMyParticipants')
            this.fetchData()
          } else {
          this.$store.commit("setGeneralMsg", {
            type: "error",
              msg: this.$t("errors.accessDenied")
          });
          }
        })
        .catch(()=>{
          this.$store.commit("setGeneralMsg", {
            type: "error",
              msg: this.$t("errors.connexionIssue")
          });
        });
      }
    },
    updateParticipant(participant){
      this.nameButton = "updateButton"
      this.title = "myParticipants.updatePopupTitle"
      this.selectedParticipant = participant
      this.$root.$emit('bv::toggle::modal', 'detailParticipant','#createParticipant')
    },
    createParticipant(){
      this.nameButton = "createButton"
      this.title = "myParticipants.createPopupTitle"
      this.selectedParticipant = {}
      this.$root.$emit('bv::toggle::modal', 'detailParticipant', '#createParticipant')
    },
    fetchData(){
      if (this.$store.state.myParticipants.length === 0){
        this.$store.dispatch('getMyParticipants')
      }
      this.dataSearch = this.dataInit
      this.extractPaginate()
    },
  }
}
</script>
