<template>
  <div>
    <b-modal id="manageParticipants" ref="manageParticipants" :title="$t('myCollection.manageParticipants.title')">
      <b-alert variant="warning" :show="generalMsgExists">{{ generalMsg }}</b-alert>
      <b-card>
        <b-card-text class="text-right">
          <b-button @click="createParticipant" ref="createParticipant" variant="warning">{{ $t('myCollection.myParticipants.createButtonTitle')}}</b-button>
          <p>{{ $t('myCollection.manageParticipants.help')}}</p>
        </b-card-text>
        <b-card-text>
          <b-form-select v-model="selectedParticipants" multiple :select-size="10" label-field="lastName" text-field="firstName">
            <b-form-select-option class="my-2" v-for="participant in myParticipants" :key="participant.id" :value="participant['@id']">
                {{ participant.firstName}} {{ participant.lastName}}
            </b-form-select-option>
          </b-form-select>
          <p>{{ $t('myCollection.manageParticipants.nbSelected')}}: {{ selectedParticipants.length}}</p>
        </b-card-text>
      </b-card>
      <template #modal-footer="{ ok, cancel }">
        <b-button-group align-h="start">
          <b-button size="sm" variant="primary" @click="reset">
            {{ $t('myCollection.resetButton')}}
          </b-button>
        </b-button-group>
        <b-button-group class="mr-2 ml-auto">
          <b-button size="sm" class="mx-2" variant="danger" @click="cancel">
            {{$t('myCollection.cancelButton')}}
          </b-button>
          <b-button size="sm" variant="success" @click="onSubmit">
            {{ $t('myCollection.updateButton')}}
          </b-button>
        </b-button-group>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ENV from "../../env.config";

/**
 * Component used to display a modal where the user can select participants
 * @displayName participantsSelection
 */
export default {
  name: 'participantsSelection',
  data() {
    return {
      /**
       * {Array} contain the list of the current selection
       */
      selectedParticipants:[],
      /**
       * {Array} save the list of the participants when the modal is displayed
       */
      saveParticipants:[],
      /**
       * {object} used to create a new participant directly in the modal
       */
      selectedParticipant:{},
      title:"",
      /**
       * {boolean} true to display the generalMsg
       */
      showMsg:false,
      /**
       * {string} error message to display in the modal
       */
      generalMsg:"",
    }
  },
  props:{
    data:Array,
    collection:String,
    id:String,
  },
  computed:{
    generalMsgExists(){
      if (this.generalMsg.length == 0){
        return false
      }
      return true
    },
    myParticipants(){

      return this.$store.state.myParticipants
    }
  },
  watch:{
    data(){
      if (this.data){
        this.selectedParticipants = this.data
      } else {
        this.selectedParticipants = []
      }
      this.saveParticipants = this.selectedParticipants
    }
  },
  methods: {
    /**
     * Method used to reset the selection
     */
    reset(){
      this.selectedParticipants = this.saveParticipants
    },
    /**
     * Method used to create a new participant directly from the modal
     */
    createParticipant(){
      this.selectedParticipant = {}
      this.$root.$emit('bv::toggle::modal', 'detailParticipant', 'createParticipant')
    },
    /**
     * Method used to cancel the modification in the modal
     */
    cancel(){
      this.selectedParticipants=[]
      this.$root.$emit('bv::toggle::modal', 'manageParticipants', '#manage-event')
    },
    /**
     * Method used when user pressed the button ok.
     * @params {evt} event
     */
    onSubmit(evt) {
      evt.preventDefault()
      if (this.collection === 'events'){
        this.showMsg = true
        if (this.selectedParticipants.length > 0){
          this.showMsg = false
          const body = {
            name: this.eventName,
            date: this.eventDate,
            maxGiftPrice: this.eventMaxPrice,
            attendingParticipants: this.selectedParticipants,
            drawSanta: this.eventSanta
          }
          fetch(`${ENV.API_PREFIX}/api/events/${this.id}`, {
            method: "PUT",
            headers: {
              "Authorization": "Bearer "+this.$store.state.accessToken,
              "content-type": "application/json"
            },
            body: JSON.stringify(body)
          })
            .then(res => {
              if (res.status === 400){
                this.generalMsg = res.statusText
                this.showMsg = true;
              } else if (res.status === 404){
                this.generalMsg = res.statusText
                this.showMsg = true;
              } else if (res.status === 200) {
                this.$store.dispatch('getMyEvents')
                this.$parent.fetchData()
                this.$root.$emit('bv::toggle::modal', 'manageParticipants', '#manage-event')
              }
            })
            .catch(()=>{
              this.generalMsg="Connexion issue"
            });
        }
      } else if (this.collection === 'participants'){
        let addParticipants = []
        if (this.saveParticipants.length === 0){
          addParticipants =this.selectedParticipants
        } else {
          this.selectedParticipants.forEach((participant)=>{
            if (this.saveParticipants.indexOf(participant) === -1){
              addParticipants.push(participant)
            }})
        }
        let delParticipants=[]
        if (this.selectedParticipants.length === 0){
          delParticipants=this.saveParticipants
        } else {
          this.saveParticipants.forEach((participant)=>{
            if (this.selectedParticipants.indexOf(participant) === -1){
              delParticipants.push(participant)
            }})
        }
        addParticipants.forEach((addParticipant)=> { this.modifyDontPairWith(this.id, addParticipant, "POST")})
        delParticipants.forEach((delParticipant)=> { this.modifyDontPairWith(this.id, delParticipant, "DELETE")})
      }
    },
    modifyDontPairWith(participantId, dontPairWithId, method){
      const shortDontPairWithId = dontPairWithId.replace('/api/participants/','')
      fetch(`${ENV.API_PREFIX}/api/participants/${this.id}/dontpairwith/${shortDontPairWithId}`, {
        method: method,
        headers: {
          "Authorization": "Bearer "+this.$store.state.accessToken,
        },
      })
        .then(res => {
          if (res.status !== 200){
            this.generalMsg = res.statusText
            this.showMsg = true;
          } else if (res.status === 200) {
            this.$store.dispatch('getMyParticipants')
            this.$parent.fetchData()
            this.$root.$emit('bv::toggle::modal', 'manageParticipants', '#manage-event')
          }
        })
        .catch(()=>{
          this.generalMsg="Connexion issue"
        });


    }
  }
}
</script>
