<template>
  <div >
    <Navbar/>
    <h1>(404) Page NotFound</h1>
    <router-link  :to="{name:'Home'}">Redirection to Home</router-link>
    <FooterMain/>
  </div>
</template>

<script>
import Navbar from '@/components/navBar.vue';
import FooterMain from "@/components/footerMain.vue"

export default {
  name: 'DontFoundPage',
  components: {
    Navbar,
    FooterMain,
  },
}
</script>
