<template>
  <div>
    <b-card
      header-tag="header"
      footer-tag="footer">
      <template #header>
        <b-card-text class="d-flex">
          <h5 class="ml-2">{{ data.firstName}} {{ data.lastName }}</h5>
          <h6 class="ml-auto mr-2"> {{ data.email }}</h6>
        </b-card-text>
      </template>
      <h5>{{ $t('myCollection.myParticipants.dontPairWith')}}: </h5>
      <b-card-text v-if=" data.dontPairWith.length === 0">
        {{ $t('none')}}
      </b-card-text>
      <b-card-text v-for="participant in data.dontPairWith" :key="participant.id">
        {{ getFullName(participant)}}
      </b-card-text>
      <h5>{{ $t('myCollection.myParticipants.isSantaAt')}}: </h5>
      <b-card-text v-if=" data.isSantaAt.length === 0">
        {{ $t('none')}}
      </b-card-text>
      <b-card-text v-for="event in data.isSantaAt" :key="event.id" >
        {{ getFullNameEvent(event)}}
      </b-card-text>
        <template #footer class="d-flex">
          <b-button size="sm" class="ml-2 mr-auto" variant="success" @click="updateParticipant">
            {{ $t('myCollection.updateButton')}}
          </b-button>
          <b-button size="sm" class="mr-2 ml-auto" variant="danger" @click="deleteParticipant">
            {{ $t('deleteButton')}}
          </b-button>
          <b-button size="sm" class="mr-2 ml-auto" variant="warning" @click="addConstraintParticipant">
            {{$t('myCollection.myParticipants.addConstraints')}}
          </b-button>
        </template>
    </b-card>
  </div>
</template>

<script>
/**
 * Component used to display a index for a specific participant. It used to manage all actions available.
 * @displayName indexParticipants
 */
  export default {
    name: 'indexParticipants',
    props:{
      /**
       * {Object} used to store all informations for a specific participant
       */
      data:Object,
    },
    methods: {
      /**
       * Method used to emit manage-participants to the parent
       * @params {evt} event
       */
      addConstraintParticipant(evt){
        evt.preventDefault()
        this.$emit('manage-participants', this.data)
      },
      /**
       * Method used to get participants's full name formatted
       * @params {string} participantId Id of the participant
       */
      getFullName(participantId){
        const participant=this.$store.state.myParticipants.find(participant => participant['@id'] === participantId)
        if (participant != undefined){
          return `${participant.firstName} ${participant.lastName}`
        }
        return ""
      },
      /**
       * Method used to get event's full name formatted
       * @params {string} eventId Id of the event
       */
      getFullNameEvent(eventId){
        const event=this.$store.state.myEvents.find(event => event['@id'] === eventId)
        if (event != undefined){
          return `${event.name}`
        }
        return ""
      },
      /**
       * Method used to emit delete-participant to the parent
       * @params {evt} event
       */
      deleteParticipant(evt){
        evt.preventDefault()
        this.$emit('delete-participant', this.data.id)
      },
      /**
       * Method used to emit update-participant to the parent
       * @params {evt} event
       */
      updateParticipant(evt){
        evt.preventDefault()
        this.$emit('update-participant', this.data)
      }
    }
  }
</script>
