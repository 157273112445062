<template>
  <div id="summary-draw">
    <Navbar />
    <BannerMsg />
    <b-card-group deck class="m-3 mx-auto" style="max-width:50rem; min-width:20rem;">
      <b-card header-tag="header"
              header-bg-variant="light"
              footer-tag="footer"
              footer-bg-variant="light"
              align="center"
              >
              <template #header>
                <HeaderEvent :data="data"/>
              </template>
        <b-card-text>
          <h1>TO DO </h1>
          <p>Creator: {{ detailCreator.firstName }} {{ detailCreator.lastName}}</p>
        </b-card-text>
        <h5>Draw revelation </h5>
        {{ giftDraws}}

        <template #footer>
          <b-card-text class="d-flex" >
            <b-button  size="sm" variant="warning" @click="confirmYourPresence">
              Confirm your presence
            </b-button>
            <b-button  size="sm" variant="warning" @click="dontconfirmYourPresence">
              Sorry, I can't
            </b-button>
          </b-card-text>
        </template>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import Navbar from "@/components/navBar.vue"
import BannerMsg from "@/components/bannerMsg.vue"
import HeaderEvent from "@/components/headerEvent.vue"

import ENV from "@/../env.config";

/**
 * View used to display all informations for a specific draw.
 * @displayName Summary-draw
 */
export default {
  name: 'summary-draw',
  components: {
    Navbar,
    BannerMsg,
    HeaderEvent
  },
  data() {
    return {
      /**
       * data containes all informations to display
       */

      data:{},
      /**
       * URI of the specific draw
       */
      drawId:"",
      /**
       * String used to show general message when validate
       */
      generalMsg:"",
      /**
       * boolean used to show error message when validate
       */
      showMsg:false,
    }
  },
  mounted(){
    this.drawId = this.$route.params.drawId
    if (this.$store.state.myParticipants.length === 0){
      this.$store.dispatch('getMyParticipants')
    }
    this.detailCreator = this.$store.state.myParticipants.find(participant => participant['@id'] === this.data.creator)
    fetch(`${ENV.API_PREFIX}/api/events/${this.eventId}`, {
      method: "GET",
      headers:{
        "Authorization": "Bearer "+this.$store.state.accessToken,
      }
    })
      .then((res) =>  {
        if(res.status === 200) {
          return res.json()
        } else {
          this.generalMsg="Access denied"
        }
      })
      .then((res)  =>{
        if (res){
          this.data = res
          this.getDetailDraws()
        }
      })
      .catch(()=>{
        this.generalMsg="Connexion issue"
      });
    this.formatData()
  },
  computed:{
  },
  methods: {
    formParticipant(value){
      if (value.firstName && value.lastName){
        return `${value.firstName} ${value.lastName}`
      }
    },
    formatData(){
      const Dayjs = require('dayjs');
      const eventDate = Dayjs(this.data.date)
      const now = new Date()
      this.remainingTime = eventDate.diff(now, 'day')
      this.date = eventDate.format('DD/MM/YYYY')
    },
    confirmYourPresence(){
      console.log('Ok')

    },
    dontconfirmYourPresence(){
      console.log('Sorry')

    },
  }
}
</script>
