<template>
  <b-modal id="modalConfirmation" ref="modalConfirmation" title="Confirmation">
    <p>{{ $t('confirmation.question')}}</p>
    <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="danger" @click="cancel()">
        {{ $t('No')}}
      </b-button>
      <b-button size="sm" variant="success" @click="validate">
        {{ $t('Yes')}}
      </b-button>
    </template>
  </b-modal>

</template>
<script>

/**
 * Component used to display a confirmation's modal.
 * @displayName modalConfirmation
 */
  export default {
    name: 'modalConfirmation',
    methods:{
      /**
       * Method used when user pressed the button ok. Emit delete to the parent
       */
      validate(){
        this.$emit("delete")
        this.$root.$emit('bv::toggle::modal', 'modalConfirmation', '#manage-event')
      }
    }
  }

</script>
