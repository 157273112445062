import Vue from "vue";
import VueRouter from "vue-router";
import Register from "../views/register.vue";
import Login from "../views/login.vue";
import Profile from "../views/profile.vue";
import AdminParticipants from "../views/participants.vue";
import AdminEvents from "../views/events.vue";
import SummaryEvent from "../views/summaryEvent.vue";
import SummaryDraw from "../views/summaryDraw.vue";

import NotFound from "../views/notFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/",
    name: "Home",
    component: Login
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile
  },
  {
    path: "/participants",
    name: "Participants",
    component: AdminParticipants
  },
  {
    path: "/events",
    name: "Events",
    component: AdminEvents
  },
  {
    path: "/event/:eventId",
    name: "Event",
    component: SummaryEvent
  },
  {
    path: "/draw/:drawId",
    name: "Draw",
    component: SummaryDraw
  },
  {
    path: "*",
    name: "Not Found",
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
