<template>
  <div id="login">
    <Navbar />
    <BannerMsg />
    <b-card-group deck class="m-3">
      <b-card :header="$t('MenuLogin')"
        header-bg-variant="light"
        align="center">
        <b-card-text>
          <b-form @submit="submit">

            <b-form-group
              id="login-group"
              :label="$t('sign.login')"
              label-for="input-login"
              >
              <b-form-input
                id="input-login"
                v-model="login"
                type="text"
                required
                :placeholder="$t('sign.inputLogin')"
                ></b-form-input>
            </b-form-group>
              <b-form-group
                id="password-group"
                :label="$t('sign.password')"
                label-for="input-d"
                >
                <b-form-input
                  id="input-password"
                  v-model="password"
                  type="password"
                  required
                  :placeholder="$t('sign.inputPassword')"
                  ></b-form-input>
              </b-form-group>
                <b-form-checkbox class="mb-2 mr-sm-2 mb-sm-0" v-model="checkRememberMe">{{$t('sign.rememberMe')}}</b-form-checkbox>
                <b-button type="submit" class="btn btn-info">{{ $t('sign.loginButton')}}</b-button>
          </b-form>
        </b-card-text>
      </b-card>
    </b-card-group>
    <FooterMain/>
  </div>
</template>

<script>
import Navbar from "@/components/navBar.vue"
import BannerMsg from "@/components/bannerMsg.vue"
import FooterMain from "@/components/footerMain.vue"
import ENV from "../../env.config";

/**
 * View used to log in
 * @displayName Login
 */
export default {
  name: 'Login',
  components: {
    FooterMain,
    Navbar,
    BannerMsg,
  },
  data() {
    return {
      login:"",
      password: "",
      checkRememberMe:false,
    }
  },
  mounted() {
    if (this.$store.state.isConnected){
      this.$router.push({name:"Events"})
    }
  },
  methods: {
    submit(evt) {
      evt.preventDefault()
      this.$store.commit("cleanGeneralMsg")
      let body = {
        username: this.login,
        password: this.password
      }
      fetch(`${ENV.API_PREFIX}/api/login_check`, {
        method: "Post",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify(body)
      })
        .then(res => {
          if (res.status === 200) {
            return res.json()
          } else {
            this.$store.commit("setGeneralMsg", {
              type: "warning",
              msg: this.$t("errors.emailAndPasswordMistake")
            });
          }
        })
        .then(res =>{
          if (res ){
            if ( this.checkRememberMe){
              this.$store.commit('setMySecretSantaToken', res)
            }
            this.$store.commit('setStateAccessToken', res.token)
            this.$store.commit('setStateUserData', res.token)
            this.$store.commit("cleanGeneralMsg")
            this.$router.push({name:"Events"})
          }
        })
        .catch(()=>{
          this.$store.commit("setGeneralMsg", {
            type: "error",
            msg: this.$t("errors.connexionIssue")
          });
        });
    }
  }
}
</script>
