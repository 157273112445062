<template>
  <b-navbar toggleable="lg" type="dark" variant="info" class="pl-5">
    <b-navbar-brand @click="go('Home')">
      <b-img src="../../icon.santa.png" alt="Santa image" height=80 style="position:absolute; top:10px; left:10px;z-index:1;"></b-img>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-5">
        <b-nav-item class="mx-3" id="Login" v-if="!isConnected" @click="go('Login')">{{ $t('MenuLogin') }}</b-nav-item>
        <b-nav-item class="mx-3" id="Register" v-if="!isConnected" @click="go('Register')" >{{$t('MenuRegister')}}</b-nav-item>
        <b-nav-item class="mx-3" id="Manage Events"  v-if="isConnected" @click="go('Events')">{{$t('MenuMyEvents')}}</b-nav-item>
        <b-nav-item class="mx-3" id="Manage Participants" v-if="isConnected" @click="go('Participants')">{{$t('MenuMyParticipants')}}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown class="mx-3" v-if="isConnected" right>
          <template #button-content>
            <em>{{$t('MenuUser')}}</em>
          </template>
          <b-dropdown-item id="Profile" @click="go('Profile')">{{$t('MenuProfile')}}</b-dropdown-item>
          <b-dropdown-item @click="logout">{{$t('MenuSignout')}}</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="Lang" right>
          <b-dropdown-item @click="changeLocale('FR')">FR</b-dropdown-item>
          <b-dropdown-item @click="changeLocale('EN')">EN</b-dropdown-item>
        </b-nav-item-dropdown >
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
/**
 * Component uses to manage the navigation. Visible in all views
 * @displayName Navbar
 */
  export default {
    name: "Navbar",
    /**
     * Hook used to commit 'getMyScretSantaToken'
     * @public
     */
    mounted(){
      this.$store.commit('getMySecretSantaToken')
      this.setActivePage(this.$route.name)
    },
    computed:{
      /**
       * Computed variable to check is the user is connected.
       * @public
       */
      isConnected(){
        if (this.$store.state.accessToken ==='' || this.$store.state.accessToken == undefined){
          return false
        }
        return true
      },
      /**
       * Computed variable to return the active page.
       * @public
       */
      activePage(){
        return this.$route.name
      }
    },
    methods: {
      /**
       * Method used to add attribute in active page
       * @params {string} value name of the page
       * @public
       */
      setActivePage(value){
        const activeMenu = document.getElementById(value)
        if (activeMenu){
          activeMenu.setAttribute('class','active');
        }
      },
      /**
       * Method used to change the locale value
       * @params {string} newLocale name of the new locale
       * @public
       */
      changeLocale(newLocale){
        const newLocaleLower = newLocale.toLowerCase()
        localStorage.setItem("MySecretSantaLocale",newLocaleLower)
        this.$root.$i18n.locale = newLocaleLower
      },
      /**
       * Method used to load the desired page
       * @params {string} namePage name of the page destination
       * @public
       */
      go(namePage){
        if (this.$route.name != namePage){
          this.setActivePage(namePage)
          this.$router.push({name: namePage})
        }
      },
      /**
       * Method used to logout/disconnect the user
       * clean the cookie and redirect to 'Home' route
       */
      logout() {
        document.cookie = "MySecretSantaToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;SameSite=LAX;";
        this.$store.commit('resetUserData')
        this.$router.push({name:"Home"})
        this.$store.commit("setGeneralMsg", {
          type: "warning",
          msg:"Now, you're disconnected"
        });
      }
    }
  };

</script>

<style scoped>
.bg-info {
  background-color:#D4423E !important
}
</style>
