import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import ENV from "@/../env.config";

import jwt_decode from "jwt-decode";
export default new Vuex.Store({
  state: {
    UserData: {},
    accessToken: "",
    isConnected: false,
    myParticipants: [],
    myEvents: [],
    generalMsg: { error: "", warning: "" }
  },
  mutations: {
    getMySecretSantaToken(state) {
      state.isConnected = false;
      if (document.cookie.length > 0) {
        let cookieArray = document.cookie.split(";");
        for (let i = 0; i < cookieArray.length; i++) {
          if (cookieArray[i].indexOf("MySecretSantaToken") != -1) {
            const cookiename = "MySecretSantaToken=";
            state.accessToken = cookieArray[i].substring(
              cookiename.length,
              cookieArray[i].length
            );
            state.UserData = jwt_decode(state.accessToken);
            state.isConnected = true;
          }
        }
      }
      if (!state.isConnected && sessionStorage.getItem("MySecretSantaToken")) {
        state.accessToken = sessionStorage.getItem("MySecretSantaToken");
        state.UserData = jwt_decode(state.accessToken);
        state.isConnected = true;
      }
      if (state.isConnected && state.UserData.exp * 1000 < Date.now()) {
        state.isConnected = false;
        this.commit("setGeneralMsg", {
          type: "error",
          msg: this.$t("errors.jwtExpired")
        });
      }
    },
    setMySecretSantaToken(state, payload) {
      const date = new Date(0);
      const jwt = jwt_decode(payload.token);
      date.setUTCSeconds(jwt.exp);
      document.cookie =
        "MySecretSantaToken=" +
        payload.token +
        "; expires=" +
        date +
        "; path=/;SameSite=LAX;";
    },
    setStateAccessToken(state, payload) {
      if (payload != undefined) {
        sessionStorage.setItem("MySecretSantaToken", payload);
        state.accessToken = payload;
      } else {
        state.accessToken = "";
        sessionStorage.removeItem("MySecretSantaToken");
      }
    },
    setStateUserData(state, payload) {
      if (payload != "" && payload != undefined) {
        state.UserData = jwt_decode(payload);
      } else {
        state.UserData = {};
      }
    },
    resetUserData(state) {
      state.UserData = {
        username: "",
        roles: []
      };
      sessionStorage.removeItem("MySecretSantaToken");
      state.accessToken = "";
      state.isConnected = false;
    },
    setStateDatas(state, payload) {
      for (const value in payload) {
        state[value] = payload[value];
      }
    },
    setGeneralMsg(state, payload) {
      if (payload.type === "error") {
        state.generalMsg.error = payload.msg;
      } else if (payload.type === "warning") {
        state.generalMsg.warning = payload.msg;
      }
    },
    cleanGeneralMsg(state) {
      for (let type in state.generalMsg) {
        state.generalMsg[type] = "";
      }
    }
  },
  actions: {
    getUserData(context) {
      context.commit("getMySecretSantaToken");
      if (context.accessToken != "") {
        fetch("http://localhost:3000/profile", {
          method: "GET",
          headers: {
            authorization: "Bearer " + context.accessToken
          }
        }).then(res => res.json());
      }
    },
    getMyParticipants(context) {
      context.commit("getMySecretSantaToken");
      if (context.state.isConnected) {
        fetch(`${ENV.API_PREFIX}/api/participants`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + context.state.accessToken,
            "Content-type": "application/json"
          }
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            }
          })
          .then(res => {
            if (res) {
              context.commit("setStateDatas", {
                myParticipants: res["hydra:member"]
              });
            }
          })
          .catch(() => {
            context.commit("setGeneralMsg", {
              type: "error",
              msg: this.$t("errors.connexionIssue")
            });
          });
      }
    },
    getMyEvents(context) {
      context.commit("getMySecretSantaToken");
      if (context.state.isConnected) {
        fetch(`${ENV.API_PREFIX}/api/events`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + context.state.accessToken,
            "Content-type": "application/json"
          }
        })
          .then(res => {
            if (res.status === 200) {
              return res.json();
            }
          })
          .then(res => {
            if (res) {
              context.commit("setStateDatas", {
                myEvents: res["hydra:member"]
              });
            }
          })
          .catch(() => {
            context.commit("setGeneralMsg", {
              type: "error",
              msg: this.$t("errors.connexionIssue")
            });
          });
      }
    }
  }
});
