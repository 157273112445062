<template>
  <div>
    <b-modal id="detailParticipant" ref="detailParticipant" :title="title">
      <b-alert variant="warning" :show="generalMsgExists">{{ generalMsg }}</b-alert>
      <b-card>
        <b-card-text>
          <b-form @submit="onSubmit" @submit.stop.prevent novalidate>
            <b-form-group  class="mt-4">
              <label  for="feedback-firstName">{{$t('sign.firstName')}}</label>
              <b-input class="mb-2 mr-sm-2 mb-sm-0" v-model="firstName" :state="firstNameValidation" id="feedback-firstName"></b-input>
              <b-form-invalid-feedback v-if="showMsg" :state="firstNameValidation">
                {{ $t('errors.nameValidationError')}}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group  class="mt-4">
              <label  for="feedback-lastName">{{$t('sign.lastName')}}</label>
              <b-input class="mb-2 mr-sm-2 mb-sm-0" v-model="lastName" :state="lastNameValidation" id="feedback-lastName"></b-input>
              <b-form-invalid-feedback v-if="showMsg" :state="lastNameValidation">
                {{ $t('errors.nameValidationError')}}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group  class="mt-4">
              <label for="feedback-email">{{$t('sign.email')}}</label>
              <b-input type="email" v-model="email" :state="emailValidation" id="feedback-email"></b-input>
              <b-form-invalid-feedback v-if="showMsg" :state="emailValidation">
                {{ $t('errors.emailValidationError')}}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
        </b-card-text>
      </b-card>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          {{ $t('myCollection.cancelButton')}}
        </b-button>
        <b-button size="sm" variant="success" @click="onSubmit">
          {{ valideButton }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ENV from "../../env.config";

/**
 * Component used to display a modal containing all informations for a specific participant. It used to create or update a participant.
 * @displayName detailParticipant
 */
export default {
  name: 'detailParticipant',
  data() {
    return {
      /**
       * lastName used to store the lastName input
       */
      lastName:"",
      /**
       * string used to store the firstName input
       */
      firstName:"",
      /**
       * string used to store the email input
       */
      email:"",
      /**
       * boolean used to show error message when validate
       */
      showMsg:false,
      /**
       * String used to show general message when validate
       */
      generalMsg:"",
    }
  },
  props:{
    /**
     * props containing the data for a specific participant
     */
    data:Object,
    /**
     * props containing the title to display in the card
     */
    title: String,
    /**
     * props containing the name of the button to display
     */
    valideButton: String,
  },
  computed:{
    generalMsgExists(){
      if (this.generalMsg.length == 0){
        return false
      }
      return true
    },
    emailValidation(){
      if (this.showMsg){
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(this.email)
      } else {
        return null
      }
    },
    firstNameValidation(){
      if (this.showMsg){
        return /^[a-zA-Z0-9_]{3,16}$/.test(this.firstName)
      } else {
        return null
      }
    },
    lastNameValidation(){
      if (this.showMsg){
        return /^[a-zA-Z0-9_]{3,16}$/.test(this.lastName)
      } else {
        return null
      }
    },
  },
  watch: {
    lastName: function(value){
      this.lastName = value
    },
    firstName: function(value){
      this.firstName = value
    },
    email: function(value){
      this.email = value
    },
    data: function(value){
      this.email = value.email
      this.lastName = value.lastName
      this.firstName = value.firstName
    }
  },
  methods: {
    /**
     * Method used when a user press to validate the form
     * @params {events} evt
     */
    onSubmit(evt) {
      evt.preventDefault()
      this.showMsg = true
      this.$store.commit('getMySecretSantaToken')
      if (this.firstNameValidation && this.lastNameValidation && this.emailValidation && this.$store.state.isConnected){
        this.showMsg = false
        if (this.valideButton == "Create"){
          let body = {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
          }
          fetch(`${ENV.API_PREFIX}/api/participants`, {
            method: "POST",
            headers: {
              "content-type": "application/json",
              "Authorization": "Bearer "+this.$store.state.accessToken,

            },
            body: JSON.stringify(body)
          })
            .then(res => {
              if (res.status === 400){
                this.generalMsg = "Participant or email already exists"
                this.showMsg = true;
              } else if (res.status === 404){
                this.generalMsg = "Oupss"
                this.showMsg = true;
              } else if (res.status === 201) {
                this.$root.$emit('bv::toggle::modal', 'detailParticipant', '#manage-participant')
                this.$store.dispatch('getMyParticipants')
              }
            })
            .catch(()=>{
              this.generalMsg="Connexion issue"
            });
        } else if (this.valideButton == "Update"){
          let body = {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email
          }
          fetch(`${ENV.API_PREFIX}/api/participants/${this.data.id}`, {
            method: "PUT",
            headers: {
              "content-type": "application/json",
              "Authorization": "Bearer "+this.$store.state.accessToken,
            },
            body: JSON.stringify(body)
          })
            .then(res => {
              if (res.status === 400){
                this.generalMsg = "Participant or email already exists"
                this.showMsg = true;
              } else if (res.status === 404){
                this.generalMsg = "Oupss"
                this.showMsg = true;
              } else if (res.status === 200) {
                this.$store.dispatch('getMyParticipants')
                this.$root.$emit('bv::toggle::modal', 'detailParticipant', '#manage-participant')
              }
            })
            .catch(()=>{
              this.generalMsg="Connexion issue"
            });
        }
      }
    }
  }
}
</script>
