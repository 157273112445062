<template>
  <div id="summary-event">
    <Navbar />
    <BannerMsg/>
    <b-button class="m-3" @click="goEvents">&lsaquo; {{$t('ButtonBack')}}</b-button>
    <b-card-group deck class="m-3 mx-auto" style="max-width:50rem; min-width:20rem;">
      <b-card header-tag="header"
              header-bg-variant="light"
              footer-tag="footer"
              footer-bg-variant="light"
              align="center"
              >
              <template #header>
                <HeaderEvent :data="data"/>
              </template>
        <b-card-text>
          <p>{{$t('myCollection.myEvents.maxPrice')}}: {{ data.maxGiftPrice }} €</p>
          <p>{{$t('myCollection.myEvents.nbParticipants')}}: {{ attendingParticipants.length }}</p>
          <p>{{$t('myCollection.myEvents.drawSanta')}} {{ data.drawSanta }}</p>

          <p>{{$t('myCollection.myEvents.creator')}}: {{ detailCreator.firstName }} {{ detailCreator.lastName}}</p>
        </b-card-text>
        <h5>{{$t('draw.listParticipants')}}</h5>
        <b-card-text v-if="attendingParticipants.length>0">
          <b-card-text v-for="participant in attendingParticipants" :key="participant.id">
            {{ formParticipant($store.state.myParticipants.find(participantIndex => participantIndex['@id'] === participant)) }}
          </b-card-text>
        </b-card-text>
        <b-card-text v-if="isRevealed">
          <h5>{{$t('draw.revelation')}} </h5>
          <h6>{{$t('draw.theSantaIs')}}:</h6>
          <p v-if="data.drawSanta && data.santa != null">
            {{ getFullName(data.santa)}}
          </p>
          <p v-else>{{$t('draw.noSanta')}}</p>
          <h6>{{$t('draw.drawSummary')}}</h6>
          <b-card-text v-for="draw in detailDraws" :key="draw.id">
            <p>{{$t('draw.giver')}}: {{ getFullName(draw.giver)}} -> {{$t('draw.receiver')}}: {{ getFullName(draw.receiver)}}</p>
          </b-card-text>
        </b-card-text>

        <template #footer>
          <b-card-text class="d-flex" >
            <b-button  size="sm" variant="warning" @click="isRevealed = !isRevealed">
              {{ nameRevealButton}}
            </b-button>
            <b-button  size="sm" class="mr-2 ml-auto" variant="success" @click="validateEvent">
              {{$t('myCollection.validateButton')}}
            </b-button>
          </b-card-text>
        </template>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import Navbar from "@/components/navBar.vue"
import HeaderEvent from "@/components/headerEvent.vue"
import BannerMsg from "@/components/bannerMsg.vue"
import ENV from "@/../env.config";

export default {
  name: 'summary-event',
  components: {
    Navbar,
    HeaderEvent,
    BannerMsg,
  },
  data() {
    return {
      data:{},
      date:"",
      eventId:"",
      remainingTime:"",
      isRevealed: false,
      detailDraws:[],
    }
  },
  mounted(){
    this.$store.commit('getMySecretSantaToken')
    if (!this.$store.state.isConnected){
      this.$router.push({name:"Login"})
    }
    this.eventId = this.$route.params.eventId
    if (this.$store.state.myParticipants.length === 0){
      this.$store.dispatch('getMyParticipants')
    }
    if (this.$route.params.data){
      this.data=this.$route.params.data
      this.getDetailDraws()
    } else {
      fetch(`${ENV.API_PREFIX}/api/events/${this.eventId}`, {
        method: "GET",
        headers:{
          "Authorization": "Bearer "+this.$store.state.accessToken,
        }
      })
        .then((res) =>  {
          if(res.status === 200) {
            return res.json()
          } else {
            this.$store.commit("setGeneralMsg", {
              type: "error",
              msg: this.$t('errors.accesDenied')
            });
          }
        })
        .then((res)  =>{
          if (res){
            this.data = res
            this.getDetailDraws()
          }
        })
        .catch(()=>{
          this.$store.commit("setGeneralMsg", {
            type: "error",
            msg: this.$t('errors.connexionIssue')
          });
        });

    }

    this.formatData()
  },
  computed:{
    nameRevealButton(){
      if (!this.isRevealed){
        return this.$t('draw.revealButton')
      }
      return this.$t('draw.noRevealButton')
    },
    detailCreator(){
      if (this.$store.state.myParticipants.length >0 && this.data.creator){
        return this.$store.state.myParticipants.find(participant => participant['@id'] === this.data.creator)
      }
      return ""
    },
    attendingParticipants(){
      if (this.data.attendingParticipants){
        return this.data.attendingParticipants
      }
      return []
    }
  },
  methods: {
    goEvents(){
      this.$store.commit("cleanGeneralMsg");

      this.$router.push({name:'Events'})

    },
    getDetailDraws(){
      this.detailDraws = []

      this.$store.commit('getMySecretSantaToken')
      if (this.$store.state.isConnected){
        this.data.giftDraws.forEach(draw=>{
          fetch(`${ENV.API_PREFIX}${draw}`, {
            method: "GET",
            headers:{
              "Authorization": "Bearer "+this.$store.state.accessToken,
            }
          })
            .then((res) =>  {
              if(res.status === 200) {
                return res.json()
              } else if(res.status === 403) {
                this.$store.commit("setGeneralMsg", {
                  type: "error",
                  msg: this.$t('errors.accesDenied')
                });
              }
            })
            .then((res)  =>{
              if (res){
                this.detailDraws.push(res)
              }
            })
            .catch(()=>{
              this.$store.commit("setGeneralMsg", {
                type: "error",
                msg: this.$t('errors.connexionIssue')
              });
            });
        })
      }
    },
    getFullName(participantId){
      const participant=this.$store.state.myParticipants.find(participant => participant['@id'] === participantId)
      if (participant != undefined){
        return `${participant.firstName} ${participant.lastName}`
      }
      return ""
    },
    formParticipant(value){
      if (value !== undefined){
        if (value.firstName && value.lastName){
          return `${value.firstName} ${value.lastName}`
        }
      }
      return null
    },
    formatData(){
      const Dayjs = require('dayjs');
      const eventDate = Dayjs(this.data.date)
      const now = new Date()
      this.remainingTime = eventDate.diff(now, 'day')
      this.date = eventDate.format('DD/MM/YYYY')
    },
    validateEvent(){
      this.$store.commit('getMySecretSantaToken')
      if (this.$store.state.isConnected){
        fetch(`${ENV.API_PREFIX}/api/events/${this.data.id}/send_emails`, {
          method: "POST",
          headers:{
            "Authorization": "Bearer "+this.$store.state.accessToken,
          }
        })
          .then((res) =>  {
            if(res.status === 201) {
              this.$store.dispatch('getMyEvents')
              this.$router.push({name:'Manage Events'})
            } else {
              this.$store.commit("setGeneralMsg", {
                type: "error",
                msg: this.$t('errors.accesDenied')
              });
            }
          })
          .catch(()=>{
            this.$store.commit("setGeneralMsg", {
              type: "error",
              msg: this.$t('errors.connexionIssue')
            });
          });
      }
    },
  }
}
</script>
