<template>
  <div>
    <b-card
      header-tag="header"
      footer-tag="footer">
      <template #header>
        <HeaderEvent :data="data"/>
      </template>
      <b-card-text>
        <p>{{$t('myCollection.myEvents.maxGiftPrice')}}: {{ data.maxGiftPrice }} €</p>
        <p>{{$t('myCollection.myEvents.nbParticipants')}}: {{ data.attendingParticipants.length }}</p>
        <p>{{$t('myCollection.myEvents.creator')}}: {{ detailCreator.firstName }} {{ detailCreator.lastName}}</p>
      </b-card-text>
      <template #footer >
        <b-button-group class="mb-2 d-flex">
          <b-button size="sm" class="" variant="success" @click="updateEvent">
            {{ $t('myCollection.updateButton')}}
          </b-button>
        <b-button size="sm" class="mx-2" variant="danger" @click="deleteEvent">
          {{ $t('deleteButton')}}
        </b-button>
        <b-button :id="'manageParticipant-'+data.id"  size="sm" class="" variant="warning" @click="participantsEvent">
          {{$t('myCollection.manageParticipants.title')}}
        </b-button>
        </b-button-group>
        <b-button-group  class="d-flex mb-2" >
          <b-button v-if="data.giftDraws.length == 0" :id="'launchDraw-'+data.id" size="sm" class="" variant="primary" @click="drawEvent">
            {{ $t('myCollection.myEvents.launchDraw')}}
          </b-button>
          <b-button v-if="data.giftDraws.length > 0" size="sm" variant="secondary" class="" @click="resetDrawEvent">
            {{ $t('myCollection.myEvents.resetDraw')}}
          </b-button>
          <b-button v-if="data.santa == null" size="sm" :id="'lanchSantaDraw-'+data.id" class="ml-2"  variant="primary" @click="drawSanta">
            {{ $t('myCollection.myEvents.launchSantaDraw')}}
          </b-button>
          <b-button v-if="data.santa != null" size="sm" class="ml-2" variant="secondary" @click="resetDrawSanta">
            {{ $t('myCollection.myEvents.resetSantaDraw')}}
          </b-button>
        </b-button-group>
        <b-button-group class="d-flex" >
          <b-button size="sm" class="" :id="'checkEvent-'+data.id" variant="success" @click="checkEvent">
            {{ $t('myCollection.myEvents.checkEvent')}}
          </b-button>
        </b-button-group>
      </template>
    </b-card>
  </div>
</template>

<script>
import HeaderEvent from "@/components/headerEvent.vue"

/**
 * Component used to display a index for a specific event. It used to manage all actions available.
 * @displayName indexEvents
 */
export default {
  name: 'indexEvents',
  components: {
    HeaderEvent,
  },
  data(){
    return {
      /**
       * {string} used to store the date of the event
       */
      date:"",
      /**
       * {string} used to store the remaining time before the event
       */
      remainingTime:"",
      /**
       * {Objectt} used to store all creator's informations
       */
      detailCreator:{},
    }
  },
  props:{
    /**
     * {Object} used to store all informations for a specific event
     */
    data:Object,
  },
  computed:{
    myParticipants(){
      return this.$store.state.myParticipants
    }
  },
  mounted(){
    if (this.$store.state.myParticipants.length === 0){
      this.$store.dispatch('getMyParticipants')
    } else {
      this.detailCreator = this.$store.state.myParticipants.find(participant => participant['@id'] === this.data.creator)
    }
    this.formatData()
  },
  watch:{
    data(){
      this.formatData()
    },
    myParticipants(){
      this.detailCreator = this.$store.state.myParticipants.find(participant => participant['@id'] === this.data.creator)
    }
  },
  methods: {
    /**
     * Method used to format in the valid format the date and remainingTime
     */
    formatData(){
      const Dayjs = require('dayjs');
      const eventDate = Dayjs(this.data.date)
      const now = new Date()
      this.remainingTime = eventDate.diff(now, 'day')
      this.date = eventDate.format('DD/MM/YYYY')
    },
    /**
     * Method used to emit manage-participant to the parent when the button participantsEvent is pressed
     * @params {evt} event
     */
    participantsEvent(evt){
      evt.preventDefault()
      this.$emit('manage-participants', this.data)
    },
    /**
     * Method used to redirect to Event views
     * @params {evt} event
     */
    checkEvent(evt){
      evt.preventDefault()
      this.$router.push({name:"Event", params:{eventId: this.data.id, data: this.data}})
    },
    /**
     * Method used to emit draw-event to the parent
     * @params {evt} event
     */
    drawEvent(evt){
      evt.preventDefault()
      this.$emit('draw-event', this.data.id, "POST")
    },
    /**
     * Method used to emit reset-draw-event to the parent
     * @params {evt} event
     */
    resetDrawEvent(evt){
      evt.preventDefault()
      this.$emit('reset-draw-event', this.data.id, "DELETE")
    },
    /**
     * Method used to emit delete-event to the parent
     * @params {evt} event
     */
    deleteEvent(evt){
      evt.preventDefault()
      this.$emit('delete-event', this.data.id)
    },
    /**
     * Method used to emit update-event to the parent
     * @params {evt} event
     */
    updateEvent(evt){
      evt.preventDefault()
      this.$emit('update-event', this.data)
    },
    /**
     * Method used to emit draw-santa to the parent
     * @params {evt} event
     */
    drawSanta(evt){
      evt.preventDefault()
      this.$emit('draw-santa', this.data.id, "POST")
    },
    /**
     * Method used to emit reset-santa to the parent
     * @params {evt} event
     */
    resetDrawSanta(evt){
      evt.preventDefault()
      this.$emit('reset-santa', this.data.id, "DELETE")
    }
  }
}
</script>
