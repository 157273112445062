<template>
  <b-card-text>
    <h5>{{ data.name}}</h5>
    <h5>{{ date }}</h5>
    <p v-if="remainingTime <0">{{$t('myCollection.myEvents.tooLate')}}</p>
    <p v-else-if="remainingTime > 0" >{{ $t('myCollection.myEvents.remainingTime')}}: {{ remainingTime}} {{$t('myCollection.myEvents.days')}}</p>
    <p v-else>{{$t('myCollection.myEvents.today')}}</p>
  </b-card-text>
</template>

<script>
/**
 * Component used to display the header for a specific event card.
 * @displayName HeaderEvent
 */
  export default {
    name: 'HeaderEvent',
    data(){
      return{
        /**
         * {string} used to store the remaining time before the event
         */
        remainingTime:"",
        /**
         * {string} used to store the date of the event
         */
        date:"",
      }
    },
    props:{
      /**
       * {Object} used to store all informations for a specific event
       */
      data:Object,
    },
    watch:{
      data(){
        this.formatData()
      }
    },
    mounted(){
      this.formatData()
    },
    methods: {
      /**
       * Method used to format in the valid format the date and remainingTime
       */
      formatData(){
        const Dayjs = require('dayjs');
        const eventDate = Dayjs(this.data.date)
        const now = new Date()
        this.remainingTime = eventDate.diff(now, 'day')
        this.date = eventDate.format('DD/MM/YYYY')
      },
    }
  }
</script>
