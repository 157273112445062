<template>
  <b-card :header="title"
    header-bg-variant="light"
    align="center">
    <b-card-text>
      <b-form @submit="onSubmit" @submit.stop.prevent novalidate>
        <b-form-group  class="mt-4">
          <label  for="feedback-firstName">{{ $t('sign.firstName')}}</label>
          <b-input class="mb-2 mr-sm-2 mb-sm-0" v-model="firstName" :state="firstNameValidation" id="feedback-firstName"></b-input>
          <b-form-invalid-feedback v-if="showMsg" :state="firstNameValidation">
            Your name must be 3-16 characters long.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group  class="mt-4">
          <label  for="feedback-lastName">{{ $t('sign.lastName')}}</label>
          <b-input class="mb-2 mr-sm-2 mb-sm-0" v-model="lastName" :state="lastNameValidation" id="feedback-lastName"></b-input>
          <b-form-invalid-feedback v-if="showMsg" :state="lastNameValidation">
            Your name must be 3-16 characters long.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group  class="mt-4">
          <label for="feedback-email">{{ $t('sign.email')}}</label>
          <b-input type="email" v-model="email" :state="emailValidation" id="feedback-email"></b-input>
          <b-form-invalid-feedback v-if="showMsg" :state="emailValidation">
            Your email is invalid.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group  class="mt-4">
          <label for="feedback-login">{{$t('sign.login')}}</label>
          <b-input v-model="login" :state="loginValidation" id="feedback-login"></b-input>
          <b-form-invalid-feedback v-if="showMsg" :state="loginValidation">
            Your name must be 3-16 characters long.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group class="mt-4" >
          <label  for="feedback-password">{{$t('sign.password')}}</label>
          <b-input type="password" required v-model="password" :state="passwordValidation" id="feedback-password"></b-input>
          <b-form-invalid-feedback v-if="showMsg" :state="passwordValidation">
            Your password must be X-XX characters long included number, upper case, lower case.
          </b-form-invalid-feedback>
          <b-form-valid-feedback :state="passwordValidation">
            <br/>
          </b-form-valid-feedback>
        </b-form-group>
        <b-form-group class="mt-4">
          <label for="feedback-confPassword">{{$t('sign.c_password')}}</label>
          <b-input type="password" required v-model="passwordConf" :state="confPasswordValidation" id="feedback-confPassword"></b-input>
          <b-form-invalid-feedback v-if="showMsg" :state="confPasswordValidation">
            Your password must be match with your confirmation.
          </b-form-invalid-feedback>
          <b-form-valid-feedback :state="confPasswordValidation">
            <br/>
          </b-form-valid-feedback>
        </b-form-group>
        <b-form-group class="text-right">
          <b-button type="submit" class="btn btn-info mt-3 mr-2">{{ nameButton}}</b-button>
        </b-form-group>
      </b-form>
    </b-card-text>
  </b-card>
</template>

<script>
import ENV from "../../env.config";

/**
 * Component used to display a modal containing all informations for a specific user. It used to create or update a event.
 * @displayName detailUser
 */
export default {
  name: 'DetailUser',
  data() {
    return {
      /**
       * variable {string} used to store the firstName input
       */
      firstName: "",
      /**
       * variable {string} used to store the lastName input
       */
      lastName: "",
      /**
       * variable {string} used to store the login input
       */
      login:"",
      /**
       * variable {string} used to store the email input
       */
      email: "",
      /**
       * variable {string} used to store the password input
       */
      password: "",
      /**
       * variable {string} used to store the password confirmation input
       */
      passwordConf: "",
      /**
       * boolean used to show error message when validate
       */
      showMsg:false,
      /**
       * String used to show general message when validate
       */
      generalMsg:"",
    }
  },
  props:{
    /**
     * props containing the title to display in the card
     */
    title: String,
    /**
     * props containing the name of the button to display
     */
    nameButton: String,
  },
  computed:{
    generalMsgExists(){
      if (this.generalMsg.length == 0){
        return false
      }
      return true
    },
    emailValidation(){
      if (this.showMsg){
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(this.email)

      } else {
        return null
      }
    },
    firstNameValidation(){
      if (this.showMsg){
        return /^[a-zA-Z0-9_]{3,16}$/.test(this.firstName)
      } else {
        return null
      }
    },
    lastNameValidation(){
      if (this.showMsg){
        return /^[a-zA-Z0-9_]{3,16}$/.test(this.lastName)
      } else {
        return null
      }
    },
    loginValidation(){
      if (this.showMsg){
        return /^[a-zA-Z0-9_]{3,16}$/.test(this.login)
      } else {
        return null
      }
    },
    passwordValidation(){
      if (this.showMsg){
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).{8,}$/.test(this.password)
      } else {
        return null
      }
    },
    confPasswordValidation(){
      if (this.showMsg){

        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).{8,}$/.test(this.password) && this.password === this.passwordConf
      } else {
        return null
      }
    }
  },
  methods: {
    /**
     * Method used when a user press to validate the form
     * @params {events} evt
     */
    onSubmit(evt) {
      evt.preventDefault()
      this.showMsg = true
      let body = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        login: this.login,
        password: this.password
      }
      this.$store.commit('getMySecretSantaToken')
      if (this.$store.state.isConnected){
        fetch(`${ENV.API_PREFIX}/api/register`, {
          method: "POST",
          headers: {
            "content-type": "application/json"
          },
          body: JSON.stringify(body)
        })
          .then(res => {
            if (res.status === 400){
              this.generalMsg = "User or email already exists"
              this.showMsg = true;
            } else if (res.status === 404){
              this.generalMsg = "Oupss"
              this.showMsg = true;
            } else if (res.status === 201) {
              this.$router.push({name:"Login"})
            }
          })
      }
    }

  }
}
</script>
