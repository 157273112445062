<template>
  <b-img id="footerMain" src="@/../footerBanner.png" alt="Footer banner image" fluid ></b-img>
</template>
<script>
export default {

  mounted(){
  this.setFooterPosition()
  },
  computed:{
    contentHeight(){
      return document.getElementById('app').offsetHeight;
}
  },
  watch:{
    contentHeight(){
  this.setFooterPosition()
}

  },
  methods:{
    setFooterPosition(){
      if (document.getElementById) {
        let windowHeight = window.innerHeight
        if (windowHeight>0) {
          let footerElement=document.getElementById('footerMain');
          let footerHeight=footerElement.offsetHeight;
          if (windowHeight-(this.contentHeight+footerHeight)>=0) {
            footerElement.style.position='relative';
            footerElement.style.bottom='0px';
          }
          else {
            footerElement.style.position='relative';
          }
        }
      }

    }
  },
}
</script>
<style>
</style>
