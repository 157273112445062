<template>
  <b-modal id="modalLaunchDraw" ref="modalLaunchDraw" :title="$t('draw.title')">
    <b-form @submit="launch" @submit.stop.prevent novalidate>
      <b-form-group  class="mt-4">
        <label  for="feedback-maxTry">{{ $t('draw.maxTry')}}</label>
        <b-input class="mb-2 mr-sm-2 mb-sm-0" v-model="maxTry" :state="maxTryValidation" id="feedback-maxTry"></b-input>
        <b-form-invalid-feedback v-if="showMsg" :state="maxTryValidation">
          {{ $t('draw.errorMaxTry')}}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group  class="mt-4">
        <label  for="feedback-previousCheck">{{ $t('draw.nbEventToCheck')}}</label>
        <b-input class="mb-2 mr-sm-2 mb-sm-0" v-model="previousEventsToCheck" :state="previousCheckValidation" id="feedback-previousCheck"></b-input>
        <b-form-invalid-feedback v-if="showMsg" :state="previousCheckValidation">
          {{ $t('draw.errorMaxTry')}}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group  class="mt-4">
        <label  for="feedback-previousCheck">{{ $t('draw.sendEmails')}}</label>
        <b-form-select v-model="sendEmails" class="mb-3" >
          <b-form-select-option :value=true>{{$t('Yes')}}</b-form-select-option>
          <b-form-select-option :value=false>{{ $t('No')}}</b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-form>

    <template #modal-footer="">
      <b-button size="sm" variant="danger" @click="cancelLaunch">
        {{ $t('myCollection.cancelButton')}}
      </b-button>
      <b-button size="sm" variant="success" @click="launch">
        {{ $t('launch')}}
      </b-button>
    </template>
  </b-modal>

</template>
<script>
/**
 * Component used to display a modal with all paramaters mandatory to launch the draw.
 * @displayName modalLaunchDraw
 */
  export default {
    name: 'modalLaunchDraw',
    data(){
      return{
        /**
         * {number} is the max try
         */
        maxTry:5,
        /**
         * {boolean} send Emails directly after the draw
         */
        sendEmails:false,
        /**
         * {number} is the number of previous event to check
         */
        previousEventsToCheck:10,
        /**
         * {boolean} true if you want to show error message
         */
        showMsg:false,
      }
    },
    props:{
      /**
       * {string} Id of the event
       */
      eventId: String,
    },
    computed:{
      maxTryValidation(){
        if (this.showMsg){
          return !isNaN(this.maxTry) && this.maxTry > 0
        }
        return null
      },
      previousCheckValidation(){
        if (this.showMsg){
          return !isNaN(this.previousEventsToCheck) && this.previousEventsToCheck > 0
        }
        return null
      },
    },
    watch:{
      eventId(){
        this.maxTry=5,
          this.sendEmails=false,
          this.previousEventsToCheck = 10
      }
    },
    methods:{
    /**
     * Method used to cancel the modification in the modal
     */
    cancelLaunch(){
      this.maxTry=5
      this.sendEmails = false
      this.previousEventsToCheck = 10
      console.log('test')
      this.$root.$emit('bv::toggle::modal', 'modalLaunchDraw', '#manage-event')
    },
      /**
       * Method used when a user valide the parameter. Emit launch to the parent
       * @params {evt} evt
       */
      launch(evt){
        evt.preventDefault()
        this.showMsg = true
        if (this.maxTryValidation && this.previousCheckValidation){
          const params={maxTry:parseInt(this.maxTry),previousEventsToCheck:parseInt(this.previousEventsToCheck), sendEmails:this.sendEmails}
          this.$emit("launch",params )
          this.$root.$emit('bv::toggle::modal', 'modalLaunchDraw', '#manage-event')
        }
      }
    }
  }

</script>
